@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.grad{
    background: -webkit-linear-gradient(180deg,#fa0000, #eee344);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.sec_2{
    /* background: url('../Assests/DeWatermark.ai_1723613849741.png'); */
    /* background: url('../Assests/Web.png'); */
    /* background: url('../Assests/DeWatermark.ai_1723613849741.png'); */
    /* background: url('../Assests/DeWatermark.ai_1723617727403.png'); */
    background-image:linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url('../Assests/WhatsApp\ Image\ 2024-08-14\ at\ 12.14.41\ PM.jpeg');
    min-height: 100vh;
    background-size: cover;
    background-position: center;
}

/* 
.oswald-<uniquifier> {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
} */